import React, { useState, useEffect } from "react";
import "./Navbar.css";
import logo from "./logo.png";
import logo_jaune from "../img/pictogrammes/cddp-jaune.svg";
import navicon from "../img/pictogrammes/iconnav.svg";
import { Link } from "react-router-dom";
import { FaireunDon } from "../Fonctions.js"
import { FaireunDonNoir } from "../Fonctions.js"


export default function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [largeur, setLargeur] = useState(window.innerWidth);

  const toggleNavSmallScreen = () => {
    setToggleMenu(!toggleMenu);
  };

  useEffect(() => {
    const changeWidth = () => {
      setLargeur(window.innerWidth);

      if (window.innerWidth > 500) {
        setToggleMenu(false);
      }
    };

    window.addEventListener("resize", changeWidth);

    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <nav className="navbar-static-top">
      {(toggleMenu || largeur > 500) && (
        <ul className="list">
          <li className="items">
            <img className="logocddp" src={logo_jaune} alt="" />  
          </li>
          <Link to="/">
            <li className="items" onClick={toggleNavSmallScreen}><span class="arc">A</span>ccueil</li>
          </Link>
          {/* <Link to="resultat">
            <li className="items" onClick={toggleNavSmallScreen}>Résultats</li>
          </Link> */}
          <Link to="course">
            <li className="items" onClick={toggleNavSmallScreen}>Inscriptions</li>
          </Link>
          <Link to="recherche">
            <li className="items" onClick={toggleNavSmallScreen}>La <span class="arc">R</span>echerche</li>
          </Link>
          <Link to="partenaires">
            <li className="items" onClick={toggleNavSmallScreen}>Nos Partenaires</li>
          </Link>

          <Link to="contact">
            <li className="items" onClick={toggleNavSmallScreen}>Nous <span class="arc">C</span>ontacter  </li>
          </Link>

          <FaireunDonNoir/>
          {/* <Link to="contact">
            <li className="items" onClick={toggleNavSmallScreen}><span class="arc">C</span>ontacts</li>
          </Link> */}
          <a rel="noreferrer" target="_blank" href="https://www.helloasso.com/associations/la-course-du-petit-prince-la-cdpp/boutiques/vente-produits-la-cdpp/widget">
            <li className="items">La Boutique</li></a>
        </ul>
      )}
      <img onClick={toggleNavSmallScreen} id="navicon" src={navicon} alt="" />
    </nav>
  );
}
