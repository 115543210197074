import React from "react"
import '../Course/Inscriptions.css'
// import qrcodeinscription from '../img/qrcodeinscription.png'
import pdf from '../img/pictogrammes/pdf.svg'
import runningconseil from '../img/runingconseil.png'
import protiming from '../img/protiming.png'

import { Titre } from "../Fonctions"

export default function Inscriptions() {
    return (
        <div className="container" id='inscription'>
            <Titre libelle="Inscriptions" color="blue" image="cddp" />
            <div class="row">
                {/* <h2>Ouverture du 1er mars 2024 au 8 mai 2024</h2>
                <h2>Lieu : Commune de Pessac</h2> */}
                {/* <div id="qrcodeinscription" className="col card-prog transparent btns">
                    <img className="img-fluid mx-auto" src={qrcodeinscription} alt='' width="300px" />
                </div> */}
                <div className="col card-prog xtransparent btns">
                    <button type="button" className="col-12 btn xbtn-warning">
                        <a target="_blank" rel="noreferrer" href="https://www.protiming.fr/Runnings/detail/7619">
                            <h1>Inscriptions en ligne</h1>
                            <img id="protiming" src={protiming} alt=""/>
                        </a>
                        
                    </button>
                </div>
                <div className="col card-prog xtransparent btns">
                    <button type="button" className="btn xbtn-warning">
                        <h1>Consultez le règlement</h1>
                        <a href={process.env.PUBLIC_URL + '/documents/reglement.pdf'} download>
                            <img src={pdf} alt="" width="50px" height="50px"></img></a>
                    </button>
                </div>
            </div>

            {/* <Titre libelle="Retrait des dossards" color="blue" image="cddp" />
            <div id="dossards" class="row">
                <div className="col card-prog yellow">
                    <h1>le samedi 4 mai 2024, de 10h à 19h</h1> 
                    <h2>au magasin <br></br>
                        <a target="_blank" href="https://www.runningconseilpessac.com/" >
                            <img src={runningconseil} alt=""/>
                        </a><br></br>
                    1 place du Monteil 33600 Pessac</h2>
                </div>
                <div className="col card-prog yellow">
                    <h1>le 8 mai 2024 de 14h à 19h</h1>
                    <h2>sur le site de la forêt du Bourgailh<br></br> 160, avenue de Beutre 33600 Pessac</h2>
                </div>
                <div className="col card-prog yellow">
                    <h1>le 9 mai 2024 (jour de la course)</h1>
                    <h2>à partir de 8h</h2>
                    <h2>sur le site de la forêt du Bourgailh<br></br> 160, avenue de Beutre 33600 Pessac</h2>
                </div>
            </div> */}

            <Titre libelle="Services gratuits" color="blue" image="cddp" />
            <div class="row">
                <div className="col card-prog yellow">

                    <p class="pre">
                        Nous mettons à disposition des participants aux trails les services gratuits suivants.
                    </p>
                    <p class="pre li">
                        Une consigne abritée et surveillée sur le village pour les sacs (1 par personne).
                    </p>
                    <p class="pre li">
                        Un stand kiné à l’arrivée.
                    </p>
                    <p class="pre li">
                        2 postes de ravitaillement seront placés sur le parcours trail 15 km aux 5ème km, 10ème  km et à l’arrivée pour les coureurs.
                    </p>
                    <p class="pre li">
                        Des postes de ravitaillement séparés pour les enfants et les marcheurs à l’arrivée sur le village.
                    </p>
                    <p class="pre li">
                        A l’arrivée des courses enfants, nous mettons en place un sas pour que les parents récupèrent en toute sécurité leurs enfants. Nous ne laisserons pas sortir seuls les enfants, merci aux parents d’être présents.
                    </p>

                </div>
            </div>


        </div>
    )
}