import './Footer.css';
import React from "react"
import logo from '../img/logo.png'
import instagram from "../img/pictogrammes/instagram.svg";
import facebook from "../img/pictogrammes/facebook.svg";
import youtube from "../img/pictogrammes/youtube.svg";
import email from "../img/pictogrammes/email.svg";


export default function Footer() {
  return (
    <footer id="footer" className="d-flex flex-wrap justify-content-between align-items-center border-top">
      <p className="text-body-secondary">© 2024 Company, Inc</p>

      <img src={logo} height="30px" alt='' />

      <ul className="nav col-md-7 col-xs-12 justify-content-end footerflex">
        <li className="nav-item"><a target="_blank" rel="noreferrer" href="/" className="nav-link px-2 text-body-secondary">Accueil</a></li>
        <li className="nav-item"><a target="_blank" rel="noreferrer" href="/course" className="nav-link px-2 text-body-secondary">La Course</a></li>
        {/* <li className="nav-item"><a target="_blank" rel="noreferrer" href="/recherche" className="nav-link px-2 text-body-secondary">La Recherche</a></li> */}
        {/* <li className="nav-item"><a target="_blank" rel="noreferrer" href="/partenaires" className="nav-link px-2 text-body-secondary">Nos Partenaires</a></li> */}
        <li className="nav-item"><a target="_blank" rel="noreferrer" href="/contact" className="nav-link px-2 text-body-secondary">Nous Contacter</a>
          <div className="gafam">
            <a rel="noreferrer" href='/contact'>
              <img src={email} className="gafam" alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/lacoursedupetitprince/'>
              <img src={instagram} className="gafam" alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/LaCourseDuPetitPrince?locale=fr_FR'>
              <img src={facebook} className="gafam" alt="" />
            </a>
            <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCqSd53LavWQdZOoRMsCQ_Og'>
              <img src={youtube} className="gafam" alt="" />
            </a>
          </div>
        </li>
      </ul>
    </footer>
  )
}