import './Home.css';
import React from "react"
import affiche from '../img/affiche.png'
import voeux from '../img/voeux.png'
import petitprince from '../img/petitprince.png'
import sarcomes from '../img/sarcome.png'
import deontologie from '../img/deontologie.png'
import Fondateurs from "./Fondateurs"
import CarouselHome from "./Carousel"
import VideoPlayer from "./VideoPlayer"
import condor from "../img/rhucondor.png"
import { FaireunDon } from "../Fonctions.js"
// import Info from '../Info/InfoAccueil.js';
import { Link } from "react-router-dom";
const videoId = "KXcoS88huzU"
export default function Home() {
    return (
        <div className="App">
            <FaireunDon />
            <div className="container-fluid" id='home'>
                {/* <ul className="list" id="link_to_resultat">
                    <Link to="resultat">
                        <li className="items" >Les Résultats de l'édition 2024 sont ici.</li>
                    </Link>
                </ul> */}
                <div className='row bandeau'>
                    <div className='col-sm-3 col-xs-12 img'>
                        <img className="flip" id="voeux" src={voeux} alt='' />
                    </div>
                    <div className="col-sm-6 col-xs-12">
                        <div className='testgrid'>
                            <h1>Edition 2025</h1>
                            <h1>Venez nous rejoindre pour aider la recherche contre les sarcomes</h1>
                            <h2>Que vous soyez compétiteur, amateur, adulte, enfant, coureur, marcheur, participant, spectateur ou bénévole, nous vous invitons à venir courir et marcher pour aider la recherche contre les sarcomes.</h2>
                            <div className='col-12'>
                                <h3><span className="AppDate">Le 29 MAI 2025</span></h3>
                            </div>
                            <div className='col-12'>
                                <h3><span className="AppDate">au BOURGAILH - PESSAC, 33600</span></h3>
                            </div>
                        </div>
                    </div>
                    <div className='col-sm-3 col-xs-12 img'>
                        <img className="flip" id="affiche" src={affiche} alt='' />
                    </div>
                </div>
            </div>


            <div className="container-fluid" id="homecddp">
                <div className='row'>

                    <div className='col-sm-3 col-xs-12 img'>
                        <img id="ximgpetitprince" src={petitprince} alt='' />
                    </div>
                    <div className='col-sm-9 col-xs-12'>
                        <div className='testgrid'>
                            <h1>La Course du Petit Prince</h1>
                            <p>
                                Créée en 2022, la course du Petit Prince (CdPP) est un événement caritatif organisé dans la forêt du Bourgailh, à Pessac (33).
                            </p>
                            <p>
                                À cette occasion, nous organisons des marches et des courses pédestres enfants (6-13 ans) et adultes, sur un circuit 100% nature entre la forêt du Bourgailh, les lacs de Cap de Bos et Romainville ainsi que les bois des sources du Peugue.
                            </p>
                            <p>
                                Les inscriptions aux épreuves sont payantes et reversées intégralement au
                            </p>
                            <p>
                                <div className='homerhucondor'>
                                    <a target="_blank" rel="noreferrer" href='https://condorprogram.com/'>
                                        PROGRAMME DE RECHERCHE NATIONAL RHU CONDOR SUR LES SARCOMES (Institut Bergonié)<br />
                                        <img id="rhucondor" src={condor} alt="" /></a>
                                </div> avec les dons des partenaires (entreprises et particuliers), pour aider la recherche contre les sarcomes – cancers rares des enfants et jeunes adultes.
                            </p><p>
                                Aucun bénéfice n’est conservé par notre association.
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid" id='cause'>
                <div className='row bandeau'>
                    <div className='col-sm-8 col-xs-12'>
                        <div className='testgrid'>
                            <h1>Notre cause autour des sarcomes</h1>
                            <p>Les sarcomes sont des tumeurs rares représentant 15% des cancers de l’enfant et des jeunes adultes.
                                Ce sont des cancers agressifs qui touchent les tissus mous du corps, les viscères ou les os (ostéosarcomes).
                                Parce qu’ils sont rares, ils ont très peu bénéficié des avancées thérapeutiques au cours des 30-40 dernières années.
                            </p>
                        </div>
                    </div>
                    <div className='col-sm-4 col-xs-12 img'>
                        <img src={sarcomes} id="imgsarcomes" alt='' />
                    </div>
                </div>
            </div>

            <div className="container-fluid" id="whoami">
                <div className='row'>
                    <div className='col-sm-4 col-xs-12 img'>
                        <img id="ximgpetitprince" src={petitprince} alt='' />
                    </div>
                    <div className='col-sm-8 col-xs-12 '>
                        <div className='testgrid'>
                            <h1>Qui sommes nous ?</h1>
                            <p>
                                En 2022, la détermination des parents de Corentin, jeune adulte décédé d'un sarcome en 2019, épaulés par plus de 70 bénévoles engagés, a permis la création de la première édition de La Course du Petit Prince
                            </p>
                            <p>
                                Ce projet est soutenu, depuis son début, par l'Institut Bergonié, la Mairie de Pessac et des partenaires donateurs publics et privés
                            </p>
                            <p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container-fluid" id='deontologie'>
                <div className='row bandeau'>
                    <div className='col-sm-8 col-xs-12'>
                        <h1>Déontologie</h1>
                        <h2>Transparence</h2>
                        <p>Nous rassurons nos donateurs en fournissant pour chaque édition, un rapport détaillé des sommes collectées et reversées à la recherche -déduction faite des frais d’organisation- .</p>
                        <h2>Efficience</h2>
                        <p>Nous garantissons que les sommes reversées vont directement à des programmes de pole recherche sur les sarcomes.</p>
                        <h2>Traçabilité</h2>
                        <p>En lien avec les programmes, nous informons sur les avancées et résultats obtenus.</p>
                    </div>
                    <div className='col-sm-4 col-xs-12 img'>
                        <img src={deontologie} id="imgdeontologie" alt='' />
                    </div>
                    {/* <FaireunDon /> */}
                </div>

            </div>

            <div className="container-fluid" id="fondateur">
                <h1>Merci à nos partenaires</h1>
                <Fondateurs></Fondateurs>
            </div>
        </div>
    )
}