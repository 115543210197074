import React from "react"
import '../Course/Course.css'
import { ImagesParcours, BtnParcours } from './Parcours.js';
import { Titre } from "../Fonctions.js"
import Carousel from './Carousel.js';
import { FaireunDon } from "../Fonctions.js"

export default function Programme() {

    const parcours = {
        "p5": { "link": "", "title": "marche", "horaire": "09h00", "id": "5", "tarif": "5,80", "tarifj": "7", "distance": "5", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20232-Marche-5km", "unit": "kms" },
        "p11": { "link": "", "title": "marche", "horaire": "09h15", "id": "11", "tarif": "8", "tarifj": "9", "distance": "11", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20231-Marche-11km" , "unit": "kms"},
        "p1": { "link": "", "title": "course enfants", "horaire": "09h30", "id": "1", "tarif": "7", "tarifj": "8", "distance": "1", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20233-Course-Enfants-1km" , "unit": "km"},
        "p2": { "link": "", "title": "course enfants", "horaire": "09h50", "id": "2", "tarif": "7", "tarifj": "8", "distance": "2", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20234-Course-Enfants-2km" , "unit": "kms"},
        "p17": { "link": "", "title": "trail adultes", "horaire": "10h30", "id": "17", "tarif": "20,20", "tarifj": "24", "distance": "17", "new": "new", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20229-Trail-17km", "unit": "kms" },
        "p7": { "link": "", "title": "trail adultes", "horaire": "11h00", "id": "7", "tarif": "13,20", "tarifj": "15", "distance": "7", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20230-Trail-7km", "unit": "kms" },
        "6x2": { "link": "", "title": "relais par équipe 3", "horaire": "14h00", "id": "6x2", "tarif": "22,50", "tarifj": "30", "distance": "6x2", "inscription": "https://www.protiming.fr/Runnings/upcoming/7619-La-Course-du-Petit-Prince/20235-Relais-par-equipe-de-3-2-Boucles-de-6km", "new": "new" , "unit": "kms"},
    }

    return (
        <div className="container" id="programme">
            <Titre libelle="Programme de la journée" don={ FaireunDon } color="blue" image="cddp" />


            <div className="row">
                <div className="col card-prog">

                    <table className="table parcours-line">
                        {/* <thead>
                    <tr>
                        <th className="horaire notmobile">Horaire</th>
                        <th ></th>
                        <th className="tarif">Tarif<br></br>Internet</th>
                        <th className="tarif">Tarif<br></br>sur place</th>
                        <th className="tarif">Parcours</th>
                        <th className="tarif">Inscription</th>
                    </tr>
                </thead> */}
                        <BtnParcours parcours={parcours} list={["p5"]} />
                        <BtnParcours parcours={parcours} list={["p11"]} />
                        <BtnParcours parcours={parcours} list={["p1"]} />
                        <BtnParcours parcours={parcours} list={["p2"]} />
                        <BtnParcours parcours={parcours} list={["p17"]} />
                        <BtnParcours parcours={parcours} list={["p7"]} />
                        <BtnParcours parcours={parcours} list={["6x2"]} />
                    </table>
                </div>
            </div>
            <div className="row rowlegende">
                <div className="legende">
                    <div>Legende</div>
                    <div className="blue tiny">
                        <span className="tarifj">Tarif Internet</span>
                    </div>
                    <div className="dark tiny">
                        <span className="tarifj">Tarif sur place, le jour de la course</span>
                    </div>
                </div>
            </div>
            <ImagesParcours parcours={parcours} />

        </div>


    )
}