import React from "react"
import '../Course/Course.css'
import Deplacement from "./Deplacement.js"
import Programme from "./Programme.js"
// import Tarifs from "./Tarifs.js"
import Inscriptions from "./Inscriptions.js"
import Galeries from "./Galeries.js"
// import Programme_suite from "./Programme_suite.js"
import {FaireunDon} from "../Fonctions.js"

import Sidebar from "../Sidebar/Sidebar.js"

import flyer from '../img/flyer.png'


export default function Course() {

    return (
        <div id="Course">
            <FaireunDon />
            {/* PRESENTATION */}
            <div className="container-fluid" id='presentation'>
                <div className="row bandeau">
                    <div className="col-sm-4 col-xs-12 left">
                        <h1>La Course Du Petit Prince</h1>
                        <h3>Lieu: Commune de Pessac</h3>
                        <h3>Parcours balisés et sécurisés</h3>
                        <h3>Courses réglementées et chronométrées</h3>
                        {/* <FaireunDon/> */}
                    </div>
                    <div className="col right">
                        <p>La course du Petit Prince (La CdPP), est un événement caritatif organisé dans la forêt du Bourgailh  160 av. de Beutre 33600 Pessac en Gironde(33).</p>
                        <p>L’événement comporte des marches, des courses pédestres enfants  (6-13 ans) et adultes, sur un circuit 100% nature entre la forêt du Bourgailh, les lacs de Cap de Bos et Romainville et le bois des sources du Peugue.​</p>
                        <p>Organisé les jeudis de l’Ascension, le parcours est ouvert et adapté à tous les publics amateurs qui souhaitent avant tout prendre du plaisir dans une activité physique et/ou sportive pour une cause caritative.​</p>
                        <p>Le village départ/arrivée est installé au cœur dans du Bourgailh, au pied du belvédère. Vous y trouverez des stands de restauration, un concert sur le théâtre de nature. Ainsi que des stands partenaires ​</p>
                        <p><i>Aucun bénéfice n’est conservé par La CdPP.​</i></p>
                    </div>
                </div>
            </div>
            {/* <Sidebar/> */}
            {/* <Tarifs/> */}
            <Programme/>
            {/* <Programme_suite/> */}
            <Inscriptions/>
            <Deplacement/>
            {/* <Galeries/> */}
 

            {/* <div className="container mt-5 text-center">
            <img src={flyer}  id="flyer" alt=""/>
        </div> */}


        </div>
    )
}