import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Navbar from './Navbar/Navbar.js';
import Home from './Home/Home.js';
import Course from './Course/Course.js';
import Recherche from './Recherche/Recherche.js';
import Partenaire from './Partenaire/Partenaire.js';
import Contact from './Contact/Contact.js';
import Footer from './Footer/Footer.js';
// import R2024 from './Resultats/2024.js';

function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='course' element={<Course/>}/>
          {/* <Route path='resultat' element={<R2024/>}/> */}
          <Route path='recherche' element={<Recherche/>}/>
          <Route path='partenaires' element={<Partenaire/>}/>
          <Route path='contact' element={<Contact/>}/>
        </Routes>
        <Footer/>
      </BrowserRouter>
    </>
  );
}



export default App;
