import React from "react"
import '../Partenaire/Partenaire.css'
import iconepart from '../img/Partenaires.png'
// import Info from '../Info/InfoPartenaires';
import PartnersGallery from "./PartnersGallery.js"
import {FaireunDon} from "../Fonctions.js"

export default function Course() {
    return (
        <div id="Partenaires" className="Partenaire">
            <FaireunDon />
            {/* <Info/> */}
            <div className="container-fluid" id='pres-part'>
                <div className='row bandeau'>
                    <div className='col'>
                        <h1>Ce projet est propulsé par ceux qui nous aident.</h1>
                    </div>
                    <div className='col hideinmobile'>
                        <img src={iconepart} id="imgpart" alt='' />
                    </div>
                </div>
            </div>

            <div className="card-part">
                <h1 id="h1cardpart">Ils nous ont soutenus en 2024.</h1>
                {/* <h1 id="h1cardpart">Merci à nos partenaires!</h1> */}


                <div className="row">
                    <div className="col">
                        <PartnersGallery />
                    </div>
                </div>
            </div>
        </div>
    )
}