import React from "react"
import '../Course/Deplacement.css'
import logotbm from "../img/tbm.svg"
import googlemaps from "../img/pictogrammes/googlemaps.svg"
import googleitineraires from "../img/pictogrammes/googleitineraires.svg"
import cdpp_blanc from "../img/pictogrammes/cdpp_blanc.svg"
import {Titre} from "../Fonctions"

import flyer from '../img/flyer.png'


export default function Deplacement() {


    const googlemap = "https://www.google.fr/maps/place/For%C3%AAt+du+Bourgailh/@44.8023738,-0.6912193,17z/data=!3m1!4b1!4m6!3m5!1s0xd54dbcf9af55ddd:0x1a0d2049b23acd2!8m2!3d44.8023738!4d-0.6886444!16s%2Fg%2F1hm5t2yqd?entry=ttu"
    const googlemap_itineraire = "https://www.google.fr/maps/dir//For%C3%AAt+du+Bourgailh,+160+Av.+de+Beutre,+33600+Pessac/@44.8023738,-0.6886444,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0xd54dbcf9af55ddd:0x1a0d2049b23acd2!2m2!1d-0.6886444!2d44.8023738!3e0?entry=ttu"
    const tbmligne04 = "https://www.infotbm.com/fr/lignes/04"
    const tbmligneB = "https://www.infotbm.com/fr/lignes/60"

    return (
        <div className="container text-center" id="deplacement">
            <div class="row">
                <div className="title col card-prog blue">
                    <h1><img className="logocddp" src={cdpp_blanc} alt=""/><div>« Allez-y avec le réseau <img src={logotbm} width="100px"/> ! »</div></h1>
                </div>
            </div>      

            <h4><i>La réduction de notre empreinte carbone est une préoccupation de notre époque que nous avons placée au cœur de l'organisation de notre événement.</i>
            </h4>
            <div className="row">
                <div className="col card-prog yellow">
                    <p class="pre">
                        Nous valorisons l'utilisation des transports en commun avec notre partenaire TBM qui vous permet de vous rendre sur le site de départ en évitant les problèmes de stationnement.</p>
                    <p class="pre">
                        Mais aussi des moyens de transport dits « propres » ( vélo, trotinette, roller, …) ou à minima en covoiturant.</p>
                    <p class="pre">
                        1 parking vélo/trottinette/roller à proximité de la ligne de départ muni de barrières vauban pour les sécuriser avec vos anti-vols.
                    </p>
                    <p class="pre">
                        Parkings voitures sur le site du Bourgailh limités à environ 200 véhicules au total.</p>
                </div>
            </div>

            <div className="row">
                <div className="col card-prog dark">
                    <h2>TRAM Ligne B</h2>
                    <h3>Direction PESSAC Alouette</h3>
                    <button className="btn btn-light ">
                        <a target="_blank" rel="noreferrer" href={tbmligneB}>
                            <img src={logotbm} width="70px"></img>
                        </a>
                    </button>
                </div>
                <div className="col card-prog dark">
                    <h2>BUS Ligne 4</h2>
                    <h3>Direction PESSAC Cap de Bos</h3>
                    <button className="btn btn-light ">
                        <a target="_blank" rel="noreferrer" href={tbmligne04}>
                            <img src={logotbm} width="70px"></img>
                        </a>
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col card-prog dark">
                    <h2>Itineraires</h2>
                    <h3>Forêt du Bourgailh, 160 Av. de Beutre, 33600 Pessac</h3>
                    <button className="btn btn-light  ">
                        <a target="_blank" rel="noreferrer" href={googlemap_itineraire}>
                            <img className="googleitineraires" src={googleitineraires}></img>
                        </a>
                    </button>
                </div>
            </div>
        </div>

    )
}