import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../Contact/Contact.css'
import instagram from "../img/pictogrammes/instagram.svg";
import facebook from "../img/pictogrammes/facebook.svg";
import youtube from "../img/pictogrammes/youtube.svg";
import prince from "../img/contactprince.png";
import ynov from "../img/ynov.png";
import { Titre, FaireunDon } from "../Fonctions"

function Contact() {
  const [from_name, setNom] = useState('');
  const [user_email, setuser_email] = useState('');
  const [reply_to, setreply_to] = useState('');
  const [message, setMessage] = useState('');
  function handleChange(e) {
    if (e.target.name === 'from_name') {
      setNom(e.target.value);
    } else if (e.target.name === 'reply_to') {
      setreply_to(e.target.value);
      setuser_email(e.target.value);
    } else if (e.target.name === 'message') {
      setMessage(e.target.value);
    }
  }
  function hideMailSend(){
    var  mailSend = document.getElementById('mailSend')
    mailSend.className = "col-12 btn btn-success hidden";    
  }
  function handleSubmit(e) {
    e.preventDefault();
    // var x = emailjs.send("service_m8z81at","template_dvqs8rs",{
    //   to_name: "JF",
    //   from_name: "BARRE eric",
    //   message: "TEST",
    //   reply_to: "eduelfe@gmail.com",
    //   }, 'jUbWTcta1Vk6b4dnA');

    var  mailSend = document.getElementById('mailSend')
    mailSend.className = "col-12 btn btn-success";
    const myTimeout = setTimeout(hideMailSend, 5000);
    emailjs.sendForm('service_m8z81at', 'template_dvqs8rs', e.target, 'jUbWTcta1Vk6b4dnA')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    setNom('');
    setreply_to('');
    setMessage('');
  }

  return (
    <div id="Contacts" className="Contact">
      <FaireunDon></FaireunDon>
      <div className='container'>
        <Titre libelle="Nous Contacter" color="blue" image="cddp" />
        <div className="row">
          <div className="col-sm-6 col-xs-12 gafam">
            <a target="_blank" rel="noreferrer" href='https://www.instagram.com/lacoursedupetitprince/'>
              <img src={instagram} alt="" width="40px" />
            </a>
            <a target="_blank" rel="noreferrer" href='https://www.facebook.com/LaCourseDuPetitPrince?locale=fr_FR'>
              <img src={facebook} alt="" width="40px" />
            </a>
            <a target="_blank" rel="noreferrer" href='https://www.youtube.com/channel/UCqSd53LavWQdZOoRMsCQ_Og'>
              <img src={youtube} alt="" width="40px" />
            </a>
            <img src={prince} id="princecontactmobile" alt="" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8 col-xs-12">
            <form onSubmit={handleSubmit} id="formMail">
              <div class="col-12">
                <label for="from_name" class="form-label"><b>Nom Prénom</b></label>
                <input type="text" name="from_name" className="form-control" placeholder="Nom Prénom" value={from_name} onChange={handleChange} />
              </div>
              <div class="col-12">
                <label for="email" class="form-label"><b>Email</b></label>
                <input type="email" name="reply_to" className="form-control" placeholder="Email" value={reply_to} onChange={handleChange} />
              </div>
              <div class="col-12">
                <label for="message" class="form-label">
                  <b>Votre Message</b>
                </label>
                <textarea name="message" className="form-control" placeholder="Message" rows="8" value={message} onChange={handleChange} />
              </div>
              <div class="col-12">
                <button id="buttontext" type="submit" className="btn btn-xs btn-primary">Envoyer</button>
                <h3>Nous vous répondrons dans les plus brefs délais par mail.</h3>
                <h4><i>Votre adresse de messagerie ne sera pas publiée.</i></h4>
              </div>
              <div id="mailSend" className='col-12 btn btn-success hidden'>Le mail a bien été envoyé</div>

            </form>
          </div>
          <div class="col notmobile" >
            <img src={prince} id="princecontact" alt="" />
          </div>
        </div>
      </div>
      {/* <div className='container'>         */}
        {/* <div className="container-fluid" id="msgform">  
      </div> */}
          {/* <Titre libelle="Remerciements" color="blue" image="cddp" />
          <div id="Remerciements" className="etudiants">
            <div className="card-prog dark etudiant">
              Théo LOPEZ
            </div>
            <div className="card-prog dark  etudiant">
              Léo BOURIN
            </div>
            <div className="card-prog dark  etudiant">
              Lucas BATISTA LOBATO DE SOUZA

            </div>
            <div className="card-prog transparent">
              <img id="ynov" src={ynov} alt="" />
            </div>
          </div> */}
      {/* </div> */}
    </div>
  );
}

export default Contact;
