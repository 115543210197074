import React from "react"
// import Info from '../Info/InfoRecherche.js'
import banniererecherche from '../img/banniererecherche.png'
import './Recherche.css'
import { FaireunDon, FctTitre } from "../Fonctions.js"
import { Titre } from "../Fonctions"
import condor from "../img/rhucondor.png"
import imgnews from "../img/pictogrammes/news.png"
import imgarticle from "../img/pictogrammes/article.png"

export default function Recherche() {

    const articles = [
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_2023-retrospective-activity-7152982347515138048-XeYz?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_misabrs2023-sarcomacare-immunotherapy-activity-7112103584472674304-LOKe?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_cdpp-rhucondor-sarcoma-activity-7064855544066453504-coEg?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_rencontres-sud-ouest-tv7-tout-sur-les-activity-7084553561774149633-OJ4c?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_new-immunotherapy-strategies-for-patients-activity-7094947915902726144-28Km?utm_source=share&utm_medium=member_desktop" },
        { "titre": "", "link": "https://www.linkedin.com/posts/rhu-condor_clarivate-highlycitedresearchers-researchexcellence-activity-7132657878544326657-fnrw?utm_source=share&utm_medium=member_desktop" }
    ];

    const newsletter = [
        { "titre": "Décembre 2024", "link": "RHU-CONDOR_Newsletter-Decembre-2024_FR.pdf", "type": "pdf" },
        { "titre": "", "link": "https://sh1.sendinblue.com/vpg1o470lpfe.html?t=1683039928", "type": "link" },
        { "titre": "", "link": "https://sh1.sendinblue.com/vxvgc470lpfe.html?t=1701078687", "type": "link" },
    ];

    function get_url_trad(url) {
        const parsedURL = new URL(url);
        const { protocol, hostname, pathname, search, hash } = parsedURL;
        return protocol + "//" + hostname.replace(/\./gi, "-") + ".translate.goog" + pathname + search + "&_x_tr_sl=auto&_x_tr_tl=fr&_x_tr_hl=fr&_x_tr_pto=nui"
    }

    const listarticles = articles.map((article, index) => {
        // const urlTrad = get_url_trad(article.link)
        return (
            <div class="col">
                <div class="card-prog">
                    {/* <div class="article"> */}
                    <button key={index} src={article.link} className="btn btn-article">
                        <a target="_blank" rel="noreferrer" href={article.link}>
                            <img src={imgarticle} alt="" ></img>
                        </a>
                        <h3>Article {index + 1}</h3>
                    </button>
                    {/* </div> */}
                </div>
            </div>

        );
    });


    const listnewsletter = newsletter.map((article, index) => {
        // const urlTrad = get_url_trad(article.link);
        if (article.type === "link")
            return (
                <div class="col">
                    <div class="card-prog">
                        {/* <div class="xnewsletter"> */}
                        {/* <h3>News Letter {index + 1}</h3> */}
                        <button key={index} src={article.link} className="btn btn-newsletter">
                            <a target="_blank" rel="noreferrer" href={article.link}>
                                <img src={imgnews} alt="" ></img>
                            </a>
                            <FctTitre article={article} index={index} />
                        </button>
                        {/* </div> */}
                    </div>
                </div>
            )
        else
            return (
                <div class="col">
                    <div class="card-prog">
                        {/* <div class="xnewsletter"> */}
                        <button key={index} src={article.link} className="btn btn-newsletter">
                            <a href={process.env.PUBLIC_URL + '/documents/' + article.link} download>
                                <img src={imgnews} alt=""></img></a>
                            <FctTitre article={article} index={index} />
                        </button>
                        {/* </div> */}
                    </div>
                </div>
            )
    }
    );

    return (
        <div id="Recherches" className="container-fluid">
            <FaireunDon />
            <div className="Recherche">
                <div className="row bandeau">
                    <img id="banniererecherche" src={banniererecherche} alt="" />
                    <div class="col-sm-2 card-prog yellow">
                        <a target="_blank" rel="noreferrer" href='https://condorprogram.com/'>PROGRAMME DE RECHERCHE NATIONAL RHU CONDOR SUR LES SARCOMES<br />(Institut Bergonié)
                            <img id="condor" src={condor} alt="" /></a>
                    </div>
                    {/* <FaireunDon /> */}
                </div>
                <div className="container">
                    <div className="lettre">
                        <p class="lettre-titre">L'Année 2024</p>
                        <p>
                            Le programme <b>RHU CONDOR</b> de l'Institut Bergonié a enregistré en 2024 des avancées significatives dans la recherche sur les sarcomes, contribuant à une meilleure compréhension et prise en charge de ces tumeurs rares. Voici les principaux points :

                        </p>
                        <p class="lettre-titre">Analyse approfondie des échantillons</p>
                        <p>Plus de 1800 échantillons de sarcomes ont été analysés, permettant des progrès dans la compréhension des mécanismes biologiques et génétiques de ces cancers, essentiels pour développer des traitements ciblés et efficaces.</p>
                        <p class="lettre-titre">Préparation d’un essai clinique innovant</p>
                        <p>Un essai clinique de phase II a été préparé, centré sur les sarcomes des tissus mous.</p>
                        <p>Ce dernier combine plusieurs molécules thérapeutiques dans une approche novatrice visant à optimiser les traitements et améliorer la qualité de vie des patients.</p>
                        <p class="lettre-titre">Organisation d’un symposium scientifique</p>
                        <p>La 2ᵉ édition du symposium sur les sarcomes a favorisé les échanges entre chercheurs, cliniciens et experts, stimulant collaborations et initiatives interdisciplinaires pour accélérer les progrès scientifiques.</p>

                        <p class="lettre-titre">Ces efforts renforcent la position de CONDOR en tant qu’acteur clé dans la recherche sur les sarcomes, avec l’objectif d’offrir de nouvelles perspectives aux patients et à leurs familles.
                        </p>
                    </div>
                </div>
                <div className="container">
                    <Titre libelle="Les News Letters du RHU CONDOR" color="dark" image="condor" />
                    <div class="row">
                        {listnewsletter}
                    </div>
                    <Titre libelle="Le programme RHU CONDOR" color="dark" image="condor" />
                    <div class="row">
                        {listarticles}
                    </div>
                </div>
            </div>
        </div>
    )
}