import React from 'react';
import parcoursbtn from "../img/pictogrammes/parcours.svg"
import inscriptionbtn from "../img/inscription.png"

const showmap = (btn) => {
    var imgparcours = document.getElementsByClassName("imgparcours");
    for (let x = 0; x < imgparcours.length; x++) {
        imgparcours[x].style.display = "none";
    }

    var parcours = btn.target.getAttribute("id");
    var imageparcours = document.getElementById("parcour" + parcours)
    if (imageparcours.getAttribute("display") === "block") {
        imageparcours.setAttribute("display", "none")
        imageparcours.style.display = "none";
    } else {
        imageparcours.setAttribute("display", "block")
        imageparcours.style.display = "block";
    }
};

function ImagesParcours({ parcours }) {
    return (
        <div id="imagesparcours">
            {Object.entries(parcours).map(([key, parcour]) => (
                <img id={`parcour${key}`} key={key} src={process.env.PUBLIC_URL + '/images/parcours/' + parcour.id + '.png'} alt={`Image ${key}`} className='imgparcours' />
            ))}
        </div>
    );
}

function BtnParcours({ parcours, list }) {
    return (
        // <div className="row">
        // <table className="table parcours-line">
        <tbody>
            {Object.entries(parcours).map(([key, value]) => (
                list.includes(key) && (

                    <tr key={key}>
                        <td className='horaire notmobile'><span className={value.new}>{value.horaire}</span></td>
                        {/* <td className=' titre'>{value.title}</td> */}
                        <td className='titre mobile'><span className={value.new}><span className='distance'>{value.horaire}</span></span><br></br>{value.title} <span className='distance'>{value.distance}</span> kms</td>
                        <td className='titre notmobile'>{value.title} <span className='distance'>{value.distance}</span> {value.unit}</td>

                        <td className='tarif'>
                            <div className="col card-prog blue">
                                <span className="tarif">{value.tarif}€</span>
                            </div>
                        </td>
                        <td className='tarif'>
                            <div className="col card-prog dark">
                                <span className="tarifj">{value.tarifj}€</span>
                            </div>
                        </td>
                        <td className='btns'>
                            <div className='map'>

                                <button className="btn btn-xs btn-light" id={key} type="button" onClick={showmap}>
                                    <img id={key} src={parcoursbtn} alt="" title="Afficher le parcours"></img>
                                </button>
                                {/* </td>
                            <td className=' map'> */}
                                <button className="btn btn-xs btn-light" id={key} type="button">
                                    <a href={value.inscription} target='_inscription'>
                                        <img src={inscriptionbtn} alt="Inscription en ligne" title="Inscription en ligne"></img>
                                    </a>
                                </button>
                            </div>
                        </td>
                    </tr>
                    // <tr>
                    //     <td colSpan="6">
                    //         <img id={`parcour${key}`} key={key} src={process.env.PUBLIC_URL + '/images/parcours/' + value.id + '.png'} alt={`Image ${key}`} className='imgparcours' />
                    //     </td>
                    // </tr>

                )
            ))}
        </tbody>
        // </table>
    );
}

export { ImagesParcours, BtnParcours };
